import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typography-overview"
    }}>{`Typography Overview`}</h1>
    <p>{`Phone #s- Dash no Paren`}</p>
    <p>{`Columns in content, when to Use - columns should be used in cases of 10? items or more`}</p>
    <p>{`Mobile views of columns - should be able to stack`}</p>
    <p>{`List of 10 items or more require splitting into columns and stack in mobile.`}</p>
    <p>{`Revisit for accordions.`}</p>
    <p>{`Preference for open source fonts?`}</p>
    <p>{`Legalese/footer text 10 pt`}</p>
    <p>{`Want to use rems as default unit for typography`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      